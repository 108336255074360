import PropTypes from 'prop-types'
import React from 'react'

const FormTextArea = React.forwardRef(
  ({ errors, label, name, ...inputProps }, ref) => (
    <label className="block" htmlFor={name}>
      <span className="text-gray-700">{label}</span>
      <textarea
        className={`block w-full mt-0 px-0.5 border-0 border-b-2 ${
          errors[name]
            ? 'border-red focus:border-red'
            : 'border-gray-200 focus:border-blue'
        } focus:ring-0`}
        name={name}
        ref={ref}
        {...inputProps}
      />
    </label>
  ),
)

FormTextArea.defaultProps = {
  rows: 2,
}

FormTextArea.propTypes = {
  errors: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
}

export { FormTextArea }
