import PropTypes from 'prop-types'
import React from 'react'

const FormButton = ({ children, ...otherProps }) => (
  <button
    className="inline-block px-5 py-2 bg-blue text-white rounded-3xl text-lg font-bold leading-8 text-center disabled:opacity-60"
    type="submit"
    {...otherProps}
  >
    {children}
  </button>
)

FormButton.propTypes = {
  children: PropTypes.string.isRequired,
}

export { FormButton }
