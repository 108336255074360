import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useCallback, useState } from 'react'

import '../styles/global.css'

import { ContentMain } from '../components/common/content-main'
import { ContentSubPage } from '../components/common/content-sub-page'
import { HeaderTitle } from '../components/common/header-title'
import { CookiesBox } from '../components/cookies-box'
import { Footer } from '../components/footer'
import { FormContactUs } from '../components/form-contact-us'
import { Navigation } from '../components/navigation'
import { SEO } from '../components/seo'
import api from '../utils/api'

const ContactUs = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'contactUs' })

  const [isSent, setSent] = useState(false)
  const handleSubmitForm = useCallback(async (data, element) => {
    await api.contactUs.create(data)
    setSent(true)
    element.target.reset()
  }, [])

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage>
            {isSent && (
              <div className="mb-10 px-6 py-2 bg-green rounded-lg text-white">
                {t('success')}
              </div>
            )}
            <FormContactUs onSubmit={handleSubmitForm} />
            <div className="mx-auto xl:w-2/3 pt-10">
              <p className="whitespace-pre-line">{t('text')}</p>
            </div>
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default ContactUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
