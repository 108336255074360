import { useTranslation } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'

import { FormButton } from '../common/form-button'
import { FormInput } from '../common/form-input'
import { FormTextArea } from '../common/form-text-area'

const FormContactUs = ({ onSubmit }) => {
  const { t } = useTranslation('components', { keyPrefix: 'formContactUs' })

  const {
    formState: { errors, isDirty, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm({ mode: 'onChange' })

  return (
    <div className="mx-auto xl:w-2/3">
      <form className="grid grid-cols gap-6" onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          disabled={isSubmitting}
          errors={errors}
          label={t('firstName.label')}
          placeholder={t('firstName.placeholder')}
          {...register('firstName', { required: true })}
        />
        <FormInput
          disabled={isSubmitting}
          errors={errors}
          label={t('lastName.label')}
          placeholder={t('lastName.placeholder')}
          {...register('lastName', { required: true })}
        />
        <FormInput
          disabled={isSubmitting}
          errors={errors}
          label={t('email.label')}
          placeholder={t('email.placeholder')}
          {...register('email', { pattern: /^\S+@\S+$/u, required: true })}
        />
        <FormInput
          disabled={isSubmitting}
          errors={errors}
          label={t('subject.label')}
          placeholder={t('subject.placeholder')}
          {...register('subject', { required: true })}
        />
        <FormTextArea
          disabled={isSubmitting}
          errors={errors}
          label={t('message.label')}
          placeholder={t('message.placeholder')}
          {...register('message', { required: true })}
        />
        <FormButton disabled={!isDirty || !isValid || isSubmitting}>
          {t('send')}
        </FormButton>
        <div className="text-gray text-sm">
          This site is protected by reCAPTCHA and the Google{' '}
          <a className="text-gray" href="https://policies.google.com/privacy">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a className="text-gray" href="https://policies.google.com/terms">
            Terms of Service
          </a>{' '}
          apply.
        </div>
      </form>
    </div>
  )
}

FormContactUs.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export { FormContactUs }
