/* eslint-disable import/no-default-export */
import axios from 'axios'

import config from '../config'

const api = axios.create({
  baseURL: `${config.apiURL}/v1`,
})

export default {
  contactUs: {
    create: data => api.post('contact-us', data).then(res => res.data),
  },
}
